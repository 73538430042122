import React, { useState } from "react";
import "./Contact.css";
import HButton from "../../components/Button/HButton";
import CustomInput from "../../components/ContactInput/Input";
import TextArea from "../../components/ContactInput/textArea";
import Footer from "../../components/footer/Footer";
import { isDesktopOrLaptop } from "../../utils/Constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedBack] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleEmailCheck = (email) => {
    if (email?.includes("@")) {
      SendMail();
    } else {
      toast.error("Invalid email address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleFeedBack = (event) => {
    setFeedBack(event.target.value);
  };

  const handleClick = () => {
    const recievr_name = name; // Replace with the user's name
    const reciever_email = email; // Replace with the user's email
    const subject = "Feedback or Book Demo";
    const body = `Hello,\n\n This Is ${recievr_name} .I would like to contact to you :\n\n${feedback}`;

    const mailtoLink = `mailto:${encodeURIComponent(
      "contact@destratum.com"
    )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 100);
  };
  const styles = {
    textInput: {
      width: !isDesktopOrLaptop ? 300 : 400,
      padding: 20,
    },
    textArea: {
      width: !isDesktopOrLaptop ? 300 : 400,
      height: 120,
    },
  };

  const SendMail = async () => {
    try {
      setLoading(!loading);
      const URL = "https://healthattai.com/api/v1/sendmail";
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          name: name,
          message: feedback,
        }),
      });
      if (response.ok) {
        const jsonData = await response.json();
        setLoading(false);
        toast.success("Successfully Sent", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <div className="inputContainer1">
      <h1 className="Hedaerdes">Get In Touch</h1>
      <div className="contact-fields">
        <CustomInput
          type="text"
          onChange={handleName}
          value={name}
          // style={styles.textInput}
          placeholder={"Name"}
        />
        <CustomInput
          type="text"
          onChange={handleEmail}
          value={email}
          // style={styles.textInput}
          placeholder={"E-Mail"}
        />

        <TextArea
          type="text"
          onChange={handleFeedBack}
          value={feedback}
          // style={styles?.textArea}
          placeholder={"Ask your questions Here"}
        />

        <HButton
          text={loading === true ? "Sending..." : "Send Message"}
          onClick={() => {
            handleEmailCheck(email);
            // SendMail();
          }}
          style={{
            backgroundColor: submit ? "#4ba5fa" : null,
            color: submit ? "#fff" : null,
          }}
        />
        <ToastContainer />

        <div>
          <p className="add">AIC-AU Incubation Foundation</p>
          <p className="add">
            2nd Floor, Platinum Jubilee building, AC Tech Campus, Anna
            University, Guindy, Chennai - 600025.
          </p>
        </div>
      </div>
      <div className="last">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
