import React, { useState, useEffect, useRef } from "react";
import "./Footer.css";
import logo from "../../../assets/images/img1.png";
import footerlogo from "../../../assets/images/footerlogo.png";
import twitter from "../../../assets/images/twitter.png";
import linkedin from "../../../assets/images/linkedin.png";
import { Link } from "react-router-dom";
import { isDesktopOrLaptop, ismobile, istab } from "../../utils/Constants";
import dest from "../../../assets/images/dest.png";
import Image from "../Image/Images";
const Footer = (props) => {
  const [activeButton, setActiveButton] = useState("");
  const [visible, setVisible] = useState(false);
  const scrollRef = useRef(null);

  const scrollTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const handleScroll = () => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    setVisible(scrollTop > 100);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const contactStyles = {
    Social_img: {
      height: isDesktopOrLaptop ? 28 : istab ? 20 : ismobile ? 15 : 10,
      width: isDesktopOrLaptop ? 28 : istab ? 20 : ismobile ? 15 : 10,
    },
  };
  return (
    <div>
      <div className="footer-container">
        <div className="footer-align">
          <div className="site">
            <Image
              containerHeight={100}
              containerWidth={150}
              container={"site"}
              src={dest}
              alt={"inst"}
              style={{ height: 100, width: 100 }}
              textStyle={{ textDecorationLine: "none" }}
              href={"http://destratum.com/"}
              // class={"click"}
              // text={"Click Here"}
            />
          </div>
          {isDesktopOrLaptop ? <div class="vl" style={{ order: 3 }}></div> : ""}
          <div className="ImgP">
            {isDesktopOrLaptop ? (
              <img
                src={footerlogo}
                alt={props.alt}
                style={{
                  width: isDesktopOrLaptop
                    ? 109
                    : istab
                    ? 50
                    : ismobile
                    ? 25
                    : 15,
                  height: isDesktopOrLaptop
                    ? 87
                    : istab
                    ? 60
                    : ismobile
                    ? 30
                    : 15,
                }}
              />
            ) : (
              ""
            )}
            <p className="connect">Stay Connected: Follow us on</p>

            <div className="link">
              <div className="icon">
                <a
                  href="https://twitter.com/DestratumS"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </div>
              <div className="icon">
                <a
                  href="https://www.instagram.com/destratum_solutions/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
              <div className="icon">
                <a
                  href="https://www.youtube.com/@DestratumSolutions-wn4lu"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </div>
              <div className="icon">
                <a
                  href="https://www.linkedin.com/company/destratum/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
          {isDesktopOrLaptop ? <div class="vl" style={{ order: 1 }}></div> : ""}
          <nav className="menu">
            <p className="menuhead">General</p>
            <Link
              to="/"
              className="menubtn"
              onClick={() => {
                handleButtonClick("Home");
                scrollToTop();
              }}
            >
              Home
            </Link>
            <Link
              to="/product"
              className="menubtn"
              onClick={() => {
                handleButtonClick("Product");
                scrollToTop();
              }}
            >
              Product
            </Link>
            <div className="dropdown-container">
              <Link
                to="/about"
                onClick={() => {
                  handleButtonClick("About");
                  scrollToTop();
                }}
                className="menubtn"
              >
                About{" "}
              </Link>
            </div>
            <Link
              to="/contact"
              className="menubtn"
              onClick={() => {
                handleButtonClick("Contact");
                scrollToTop();
              }}
            >
              Contact
            </Link>

            <Link
              to="/abha"
              className="menubtn"
              onClick={() => {
                handleButtonClick("Abha");
                scrollToTop();
              }}
            >
              What'S ABHA
            </Link>
          </nav>
          {isDesktopOrLaptop ? <div class="vl" style={{ order: 2 }}></div> : ""}
          <div className="menu">
            <p className="menuhead">Support</p>
            <Link
              to="/privacy-policy"
              className="menubtn"
              onClick={() => {
                handleButtonClick("PrivacyPolicy");
                scrollToTop();
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-conditions"
              className="menubtn"
              onClick={() => {
                handleButtonClick("TermsConditions");
                scrollToTop();
              }}
            >
              Terms & Conditions
            </Link>
            <Link
              to="/faqs"
              className="menubtn"
              onClick={() => {
                handleButtonClick("FAQs");
                scrollToTop();
              }}
            >
              FAQ'S
            </Link>
          </div>
        </div>
        <div
          className={`bottom ${visible ? "show" : ""}`}
          onClick={scrollToTop}
        >
          <i
            class="fa-solid fa-arrow-up"
            style={{ fontSize: isDesktopOrLaptop ? 28 : 24, color: "#AAD3EF" }}
          ></i>
        </div>
      </div>
      <div class="center-content">
        <p>Copyright @2024 Destratum Solutions Pvt. Ltd. All Rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
