import React from "react";
import "./Abha.css";
import MainHead from "../../components/MainHeading/MainHeading";
import Footer from "../../components/footer/Footer";
import BackGround from "../../components/backGround/BackGround";
import Arrow from "../../components/Arrow/Arrow";
import {
  ArrowIcon,
  isDesktopOrLaptop,
  isSmallMobile,
  ismobile,
  istab,
} from "../../utils/Constants";
import abha from "../../../assets/images/click.png";

const Abha = () => {
  const styles = {
    circle: {
      height: isDesktopOrLaptop ? 40 : istab ? 35 : ismobile ? 25 : 20,
      width: 60,
      paddingBottom: isDesktopOrLaptop ? 40 : istab ? 35 : ismobile ? 25 : 20,
    },
    clickHere: {
      width: isDesktopOrLaptop ? 300 : istab ? 250 : ismobile ? 100 : 75,
      height: isDesktopOrLaptop ? 75 : istab ? 50 : ismobile ? 30 : 15,
    },
  };
  return (
    <div className="abhaContainer">
      <div className="abha-head1">
        <h1 className="Hedaerdes">What's ABHA</h1>

        <p className="abha-desc">
          The ABHA number is a 14-digit code that uniquely identifies you in
          India's digital healthcare system. It gives you a strong and trusted
          identity accepted by healthcare providers and payers all over the
          country.
        </p>
      </div>
      <div className="last">
        <MainHead head={"Advantages  of the ABHA Health ID Card: "} />
      </div>
      <div className="abha-head">
        <ol>
          <li className="abha-desc1">
            Reliable Identity: Have a distinct and dependable identity accepted
            by various healthcare providers.
          </li>
          <li className="abha-desc1">
            Comprehensive Benefits: Connect all healthcare perks, from public
            health initiatives to insurance plans, with your unique ABHA number.
          </li>
          <li className="abha-desc1">
            Convenient Access: Skip the queues for healthcare facility
            registration nationwide.
          </li>
          <li className="abha-desc1">
            Effortless PHR Enrollment: Easily join Personal Health Record (PHR)
            apps like the ABDM ABHA application for seamless health data
            sharing.
          </li>
        </ol>
      </div>
      <div className="last">
        <MainHead head={"Create ABHA in 3 Simple Steps"} />
      </div>
      <div className="AbhaStep">
        <Arrow text={"Enter your Aadhar number & verify with OTP"} No={"1"} />
        <div className="abha-arrow">
          {" "}
          <img src={ArrowIcon} alt="" className="abha-arrow" />
        </div>
        <Arrow text={"Enter your mobile number & verify with OTP"} No={"2"} />
        <div className="abha-arrow">
          <img className="abha-arrow" src={ArrowIcon} alt="" />
        </div>
        <Arrow text={`${"Create ABHA ID or ABHA Number"}`} No={"3"} />
      </div>
      <div className="last">
        <BackGround
          // style={{
          //   fontSize: isDesktopOrLaptop ? 34 : istab ? 25 : ismobile ? 16 : 10,
          // }}
          to={"https://healthid.ndhm.gov.in/"}
          text={"For more information about ABDM"}
          src={abha}
          // imgStyle={styles?.clickHere}
        />
      </div>
      <div className="last">
        <Footer />
      </div>
    </div>
  );
};

export default Abha;
