import React from "react";
import "./Product.css";
import CardComponent from "../../components/card/Card";
import MainHead from "../../components/MainHeading/MainHeading";
import Footer from "../../components/footer/Footer";
import img1 from "../../../assets/images/Prod1.png";
import data from "../../../assets/images/sec.png";
import care from "../../../assets/images/care.png";
import priv from "../../../assets/images/privacy.png";
import BackGround from "../../components/backGround/BackGround";
import demo from "../../../assets/images/btn.png";
import newdata from "../../../assets/images/newdata.png";
import newcare from "../../../assets/images/newcare.png";
import {
  isDesktopOrLaptop,
  isSmallMobile,
  ismobile,
  istab,
} from "../../utils/Constants";
import Bullets from "../../components/bullet point/bullet";

const Product = () => {
  console.log("====================================");
  console.log(window?.innerWidth);
  console.log("====================================");
  return (
    <div className="product-main">
      <div className="product-First">
        <div className="TextContain">
          <h1 className="product-Head">
            Prescription Made <br />
            Easy
          </h1>
          <p id="Desc">
            Empowering Doctors to deliver Exceptional Patient Care!
          </p>
        </div>
        <div className="img-Container">
          <img src={img1} alt="img" className="img-Container" />
        </div>
      </div>
      <div className="last">
        <MainHead head={"Efficient Health Data Sharing for Doctors"} />
      </div>
      <div className="CardContain">
        <CardComponent
          bullet={true}
          style={true}
          imageSrc={newdata}
          text={
            <ol
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <li>Streamlines patient information exchange</li>
              <li>Swift access to up-to-date records</li>
              <li>Enhanced collaboration among healthcare professionals.</li>
            </ol>
          }
          alignLeft
        />
      </div>
      <div className="last">
        <MainHead head={"Enhanced Patient Engagement"} />
      </div>
      <div className="CardContain">
        <CardComponent
          style={true}
          imageSrc={newcare}
          text={
            <ol
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <li>Stay connected with patients beyond the clinic</li>
              <li>Secure messaging, health articles, appointment reminders.</li>
              <li>Enhance wellness journey</li>
            </ol>
          }
        />
      </div>
      <div className="last">
        <MainHead head={"Privacy & Security"} />
      </div>
      <div className="CardContain">
        <CardComponent
          style={true}
          imageSrc={priv}
          text={
            "Your safety and privacy are our top priorities. We have implemented robust security measures and privacy protocols to protect your information."
          }
          alignLeft
        />
      </div>
      <div className="last">
        <BackGround
          to={"/contact"}
          text={"Get the app right now!.."}
          src={demo}
        />
      </div>
      <div className="last">
        <Footer />
      </div>
    </div>
  );
};

export default Product;
