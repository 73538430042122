import React, { useState, useRef } from "react";
import CardComponent from "../../components/card/Card";
import first from "../../../assets/images/pres.png";
import "./Home.css";
import HButton from "../../components/Button/HButton";
import MainHead from "../../components/MainHeading/MainHeading";
import Video from "../../components/video/Video";
import FeatureCard from "../../components/features/features";
import ach1 from "../../../assets/images/01.jpeg";
import ach2 from "../../../assets/images/02.jpeg";
import CustomInput from "../../components/ContactInput/Input";
import TextArea from "../../components/ContactInput/textArea";
import Footer from "../../components/footer/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import video from '../../../assets/Apj Abdul Kalam 7 Rules of Success Inspirational Speech - Motivational Interviews.mp4'
import { useMediaQuery } from "react-responsive";
import { isSmallMobile, ismobile, istab } from "../../utils/Constants";
const Home = ({ refer }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  console.log("====================================");
  console.log(isDesktopOrLaptop);
  console.log("====================================");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedBack] = useState("");
  const [submit, setSubmit] = useState(false);

  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleFeedBack = (event) => {
    setFeedBack(event.target.value);
  };

  const targetRef = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAchievement = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = () => {
    // const recievr_name = name;
    // const reciever_email =email;
    const subject = "Feedback or Book Demo";
    const body = `Hello,\n\n This Is ${name} .I would like to contact to you :\n\n${feedback}`;

    const mailtoLink = `mailto:${encodeURIComponent(
      "contact@destratum.com"
    )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 100);
  };
  const [loading, setLoading] = useState(false);
  const handleEmailCheck = (email) => {
    if (email?.includes("@")) {
      SendMail();
    } else {
      toast.error("Invalid email address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const styles = {
    textInput: {
      width: !isDesktopOrLaptop ? 300 : 400,
      padding: 20,
    },
    textArea: {
      width: !isDesktopOrLaptop ? 300 : 400,
      height: 120,
    },
  };

  const SendMail = async () => {
    try {
      setLoading(!loading);
      const URL = "https://healthattai.com/api/v1/sendmail";
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          name: name,
          message: feedback,
        }),
      });
      if (response.ok) {
        const jsonData = await response.json();
        setLoading(false);
        toast.success("Successfully Sent", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <div className="homeContainer">
      <div className="presContainer">
        <div className="textContainer">
          <h1 className="Head">
            Digital
            <br /> Prescriptions for
            <br /> Modern Healthcare
          </h1>
          <p className="Desc">
            {" "}
            Our platform enabling doctor with e-prescription and direct patient
            engagement
          </p>

          <HButton
            text={" Reach Us For  Demo"}
            onClick={scrollToTarget}
            // style={
            //   isDesktopOrLaptop
            //     ? { fontSize: 32, paddingHorizontal: 4, marginTop: 32 }
            //     : { fontSize: 16, paddingHorizontal: 8, marginTop: 16 }
            // }
          />
        </div>
        <div className="Img">
          <img src={first} alt="pres" className="Img" />
        </div>
      </div>
      <div className="last">
        <MainHead head={"Our Features"} />
      </div>
      <div className="features">
        <FeatureCard
          TextHead={"Digitalized Prescription"}
          Text={
            "Our app enables doctors to create and manage interoperable prescription, optimizing patient care and streamlining the process."
          }
        />
        <FeatureCard
          TextHead={"FHIR standard"}
          Text={
            "Our app utilizes the FHIR standard to ensure seamless interoperability and exchange of healthcare data for improved patient outcomes."
          }
        />
        <FeatureCard
          TextHead={"Appointments"}
          Text={
            "Efficiently manage appointments and streamline scheduling with our user-friendly appointment management system ."
          }
        />
      </div>
      <div className="last">
        <MainHead head={"Recent Achievements"} />
      </div>
      <div ref={refer} className="achievement">
        <CardComponent
          imageSrc={ach2}
          text={
            "We are thrilled to announce that Destratum Solutions has been chosen as one of the distinguished recipients of equity funding by StartupTN on 5th May 2023. This achievement marks a significant milestone in our journey and serves as a testament to the innovative vision and dedication that drive our team."
          }
          alignLeft
        />
        <CardComponent
          imageSrc={ach1}
          text={
            "Proudly collaborating with the Tirunelveli district administration, StartUpTN successfully hosted the GovTech Thon, a competition fostering innovation in governmental operations. We are thrilled to declare Destratum Solutions as a distinguished victor in public health.GovTech Thon showcased transformative solutions, and Destratum Solutions stood out for their excellence in leveraging technology to address healthcare challenges. This achievement underscores the impact of private-public collaboration in driving positive change."
          }
          alignLeft
        />
      </div>
      <div className="last">
        <MainHead head={"Ask & Engage"} />
      </div>
      <div ref={targetRef} className="inputContainer">
        <CustomInput
          type="text"
          onChange={handleName}
          value={name}
          placeholder={"Name"}
        />
        <CustomInput
          type="text"
          onChange={handleEmail}
          value={email}
          placeholder={"E-Mail"}
        />

        <TextArea
          type="text"
          onChange={handleFeedBack}
          value={feedback}
          placeholder={"Ask your questions Here"}
        />

        <HButton
          text={loading === true ? "Sending..." : "Send Message"}
          onClick={() => {
            handleEmailCheck(email);
            // SendMail();
          }}
          style={{
            backgroundColor: submit ? "#4ba5fa" : null,
            color: submit ? "#fff" : null,
          }}
        />
        <ToastContainer />
      </div>
      <div className="last">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
