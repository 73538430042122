import React, { useState } from "react";
import {
  isDesktopOrLaptop,
  isSmallMobile,
  ismobile,
  istab,
  posts,
} from "../../utils/Constants";
import "./tips.css";
import HButton from "../../components/Button/HButton";
import health from "../../../assets/images/health.png";
import { category } from "../../utils/Constants";
import SelectionTab from "../../components/selectionTab/selection";
import VideoCard from "../../components/videocard/videocard";
import Footer from "../../components/footer/Footer";

const Health_Tips = () => {
  const [types, setCategory] = useState("All Posts");
  return (
    <div className="tips-main">
      <div className="First">
        {/* <div className="Second"> */}
        <div className="tips-card">
          <div className="head-content">
            <h1 id="head">Elevate Your Well-Being</h1>
            <p id="head1">
              Hydrate, nourish with vibrant foods, and stay active daily. Your
              journey to a healthier lifestyle begins right here
            </p>
          </div>
          <div className="tip-image">
            <img src={health} alt={"Health Tips"} className="image1" />
          </div>
        </div>
        <div className="category">
          {category.map((item) => (
            <div className="tab">
              <SelectionTab text={item} onClick={() => setCategory(item)} />
            </div>
          ))}
        </div>

        <div className="tipsvideoall">
          {types === "All Posts" &&
            posts.map((value, index) => (
              <div className="cards" key={index}>
                <VideoCard
                  videoSrc={value.src}
                  topic={value.topic}
                  desc={value?.desc}
                  link={value?.link}
                />
              </div>
            ))}
        </div>

        <div className="tipsvideo">
          {types === "Healthy Lifestyle" &&
            posts
              .filter((item) => item?.category === "healthy")
              .map((value, index) => (
                <div className="cards">
                  <VideoCard
                    videoSrc={value.src}
                    topic={value.topic}
                    desc={value?.desc}
                  />
                </div>
              ))}
        </div>
        <div className="tipsvideo1">
          {types === "Facts About Health" &&
            posts
              .filter((item) => item?.category === "facts")
              .map((value, index) => (
                <div className="cards1">
                  <VideoCard
                    videoSrc={value.src}
                    topic={value.topic}
                    desc={value?.desc}
                  />
                </div>
              ))}
        </div>
      </div>
      {/* </div> */}
      <div className="last" style={{ marginTop: "5%" }}>
        <Footer />
      </div>
    </div>
  );
};
export default Health_Tips;
